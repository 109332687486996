<template>
  <div class="">
    <el-dialog
      class="dialogVisible"
      title=""
      :visible.sync="dialogVisible"
      width="86%"
      :show-close="false"
      :close-on-click-modal="false"
      :top="'40px'"
    >
    <!-- <draggable class="wrapper" v-model="getList" @start="drag=true" @end="drag=false"> -->
      <div class="header">
        <span>添加关注团队</span>
        <i class="el-icon-circle-close" @click="cancel"></i>
      </div>
      <div class="box">
        <div class="content-left">
          <el-card>
            <search-filter
              ref="searchFilter"
              :searchName="'搜索团队'"
              :keywordOptions="{
                placeholder: '请输入团队名称或创建人',
                prop: 'keyword',
                autoName: 'team',
                autocomplete: true,
              }"
              :searchOptions="[
                {
                  name: 'el-form-item',
                  options: {
                    label: '我的',
                    prop: 'my',
                    type: 'radio',
                    data: { name: 'user_group', keys: 'my' },
                    defaultValue: '全部',
                  },
                },
                {
                  name: 'el-form-item',
                  options: {
                    label: '类别',
                    prop: 'type',
                    type: 'radio',
                    data: { name: 'user_group', keys: 'type' },
                    defaultValue: '全部',
                  },
                },
              ]"
              v-model="where"
              :total="total"
              @search="currentChange(1)"
            ></search-filter>
          </el-card>

          <div style="margin: 10px; ">
            <el-button type="primary" @click="applyAttention" size="mini">申请关注</el-button>
            <el-button type="warning" @click="cancelAttention" size="mini">取消关注</el-button>
            <!-- <el-button type="primary" size="mini" @click="dialogVisible=false">取消</el-button> -->
          </div>
          <div class="table-content">
            <TeamList
              :operationType="'copyAndDownload'"
              :isSelection="true"
              :tableData="tableData"
              @getList="getList"
              @chooseId="chooseId"
              :height="400"
              :dialogVisible="dialogVisible"
              v-loading="loading"
            ></TeamList>
            <div class="pagination">
              <el-pagination
                background
                layout="total,sizes,prev, pager, next"
                :total="total"
                @current-change="currentChange"
                :page-size="pageSize"
                :current-page="page"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    <!-- </draggable> -->

    </el-dialog>
  </div>
</template>

<script>
import { teamList } from "@/api/team.js";
import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
import TeamList from "./TeamList.vue";
import SearchFilter from "@/components/com/SearchFilter";
// import draggable from 'vuedraggable';

// import draggable from '@/Utils/draggable.js';

export default {
  // directives: {
  //   draggable,
  // },
  components: {
    SearchFilter,
    Advertising,
    HotRecommend,
    TeamList,
    // draggable
  },
  props:{
    cid:{
      type:Number,
      default:()=>0
    }
  },
  data() {
    return {
      where: {},
      info: {},
      page: 1,
      pageSize: 50,
      total: 0,
      loading: false,
      tableData: [],
      sort: "",
      dialogVisible: false,
      ids:[],//勾选的团队列表
      dragging: false,
      dragStartX: 0,
      dragStartY: 0,
      initialPosition: { x: 0, y: 0 }
    };
  },
  created(){
    this.sort='from_id-desc'

  },
  methods: {
    
    chooseId(ids) {
      console.log(ids, "idsidsids");
      this.ids=ids
    },
    // 申请关注
    applyAttention(){
      console.log('申请关注')
      if (this.ids.length < 1) {
        this.$message.error("请选择要关注的团队");
        return;
      }
      this.$http
            .post("/xapi/user.group/teamFocusApply", {from_id:this.cid,to_id:this.ids})
            .then((res) => {
              console.log('申请关注成功')
              this.getTeamList()
              this.$message.success(res.msg)
            })
            .catch((err) => {
              console.log(err)
            });
    },
    // 申请关注
    cancelAttention(){
      console.log('取消关注')
      if (this.ids.length < 1) {
        this.$message.error("请选择要取消关注的团队");
        return;
      }
      this.$http
            .post("/xapi/user.group/cancelTeamFocus", {from_id:this.cid,to_id:this.ids})
            .then((res) => {
              console.log('申请关注成功')
              this.getTeamList()
              this.$message.success(res.msg)
            })
            .catch((err) => {
              console.log(err)
            });
    },
    // 打开弹窗
    openTeamDialog() {
      this.dialogVisible = true;
    },
    // 关闭取消
    cancel() {
      this.dialogVisible = false;
    },
    routeUpdate() {
      this.where = { ...this.$route.query, ...this.$route.query.params };
    },
    // 团队列表
    getTeamList(params) {
      this.loading = true;
      teamList({
        ...this.where,
        ...(params || {}),
        page: this.page,
        page_size: this.pageSize,
        sort: this.sort,
        from_id:this.cid
      })
        .then(({ data }) => {
          this.loading = false;
          this.tableData = data.data;
          this.total = data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getList(sort) {

      console.log(sort, "sort");
      this.sort = sort;
      this.getTeamList();
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getTeamList();
    },
    // 点击新建团队
    goNewProblemList() {
      if (!localStorage.getItem("user_token")) {
        return (this.$store.state.lodinShow = true);
      }
      this.$router.push({ path: "/establishTeamInfo" });
    },
    // 我管理的
    myManageList() {
      this.getTeamList(this.where);
    },
    // 我加入的
    myJoinList() {
      this.getTeamList(this.where);
    },
  },
};
</script>
<style  lang='less' scoped>

::v-deep {
  .el-card__body {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .classify {
      flex: 1;
    }
  }
}
.dialogVisible {
  ::v-deep {
    .el-dialog__header {
      padding: 0;
    // cursor: move !important; /* 将拖拽手柄设置为可拖拽的图标 */

    }
    .el-dialog__body {
      padding: 0;
    }
    .el-input__inner {
      // border-radius: 40px;
      border: 1px solid #d4d4d4;
      height: 30px;
      line-height: 30px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-top: 1px solid #ebeef5;
      //  border-bottom: 1px solid #EBEEF5;
    }
  }
  .header {
  // cursor: move !important; /* 将拖拽手柄设置为可拖拽的图标 */
    height: 35px;
    background: #00957e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    line-height: 35px;
    align-items: center;
    .el-icon-circle-close {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .body {
    padding: 0 10% 2rem;
    margin: 0 auto;
    .body-item-table {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      // .subject {
      // }
    }
    .body-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;
      .select {
        display: flex;
        justify-content: space-between;
        > div {
          width: 48%;
        }
      }
    }
    ::v-deep {
      .el-table th {
        background-color: #f2f2f2;
      }
      .el-table--striped .el-table__body tr.el-table__row--striped td {
        background: #f2f2f2;
      }
      .el-table td,
      .el-table th.is-leaf {
        border: 0;
      }
      .el-table td,
      .el-table th {
        padding: 0;
        height: 30px;
      }
      .cell {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323232;
        line-height: 30px;
      }
    }
    .select {
      margin-top: 2%;
    }
    .save-btn {
      height: 2rem;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
    }
    .save,
    .cancel {
      float: right;
      bottom: 2rem;
      right: 2rem;
      width: 40px;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      padding: 0px 16px;
      text-align: center;
      width: 74px;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
    }
    .cancel {
      margin-right: 2rem;
      color: #333333;
      background: #ffffff;
      border: 1px solid #979797;
    }
  }
}
.myTeam {
  background-color: #fff;
  // border: 1px solid #00957e;
  color: #00957e;
  height: 24px;
  // box-sizing: border-box;
}
.pagination {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    // width: 70%;
    width: 100%;
    .operate {
      display: flex;
      justify-content: flex-end;

      .new_problemList {
        margin-left: 1rem;
      }
    }
    .table-content {
      margin: 20px 0 0;
      padding: 0 1rem 1rem;
      background-color: #fff;
    }
  }
}
</style>