var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('el-dialog',{staticClass:"dialogVisible",attrs:{"title":"","visible":_vm.dialogVisible,"width":"86%","show-close":false,"close-on-click-modal":false,"top":'40px'},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"header"},[_c('span',[_vm._v("添加关注团队")]),_c('i',{staticClass:"el-icon-circle-close",on:{"click":_vm.cancel}})]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"content-left"},[_c('el-card',[_c('search-filter',{ref:"searchFilter",attrs:{"searchName":'搜索团队',"keywordOptions":{
              placeholder: '请输入团队名称或创建人',
              prop: 'keyword',
              autoName: 'team',
              autocomplete: true,
            },"searchOptions":[
              {
                name: 'el-form-item',
                options: {
                  label: '我的',
                  prop: 'my',
                  type: 'radio',
                  data: { name: 'user_group', keys: 'my' },
                  defaultValue: '全部',
                },
              },
              {
                name: 'el-form-item',
                options: {
                  label: '类别',
                  prop: 'type',
                  type: 'radio',
                  data: { name: 'user_group', keys: 'type' },
                  defaultValue: '全部',
                },
              },
            ],"total":_vm.total},on:{"search":function($event){return _vm.currentChange(1)}},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}})],1),_c('div',{staticStyle:{"margin":"10px"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.applyAttention}},[_vm._v("申请关注")]),_c('el-button',{attrs:{"type":"warning","size":"mini"},on:{"click":_vm.cancelAttention}},[_vm._v("取消关注")])],1),_c('div',{staticClass:"table-content"},[_c('TeamList',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"operationType":'copyAndDownload',"isSelection":true,"tableData":_vm.tableData,"height":400,"dialogVisible":_vm.dialogVisible},on:{"getList":_vm.getList,"chooseId":_vm.chooseId}}),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"total,sizes,prev, pager, next","total":_vm.total,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"current-change":_vm.currentChange}})],1)],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }