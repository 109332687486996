<!-- 创建团队 -->
<template>
  <div>
    <div class="establish_team">
      <!-- <div class="introduce" v-html="rule">
        {{rule}}
      </div> -->
      <!-- <el-form ref="form" :model="nameForm" :rules="rules" label-width="90px">
        <el-form-item label="团队名称" prop="name">
          <el-input
            v-model="nameForm.name"
            placeholder="请输入团队名称"
            @change="checkName"
          ></el-input>
        </el-form-item>
      </el-form> -->
      <form-create
        ref="form"
        v-model="form"
        :inline="false"
        label-width="120px"
        :label-position="'right'"
        @update="handleFormUpdate"
        :components="[
          {
            name: 'el-row',
            components: [
              {
                name: 'el-col',
                props: { span: 16 },
                components: [
                  {
                    name: 'el-row',
                    components: [
                      {
                        name: 'el-col',
                        props: { span: 24 },
                        components: [
                          {
                            name: 'form-item',
                            props: {
                              prop: 'name',
                              label: '团队名称',
                              required: true,
                              rules: [
                                {
                                  required: true,
                                  message: '请输入团队名称',
                                  trigger: 'change',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'el-col',
                    props: { span: 24 },
                    components: [
                      {
                        name: 'form-item',
                        props: {
                          prop: 'province_id',
                          required: true,
                          label: '地域',
                          type: 'select',
                          data: {
                            name: 'user_group',
                            keys: 'province_id',
                            isAll: false,
                          },
                          rules: [
                            {
                              required: true,
                              message: '请选择地域',
                              trigger: 'change',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    name: 'el-col',
                    props: { span: 24 },
                    components: [
                      {
                        name: 'form-item',
                        props: {
                          prop: 'type',
                          required: true,
                          label: '类别',
                          type: 'select',
                          data: {
                            name: 'user_group',
                            keys: 'type',
                            isAll: false,
                          },
                          filterable: false,
                          rules: [
                            {
                              required: true,
                              message: '请选择地域',
                              trigger: 'change',
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },

              {
                name: 'el-col',
                props: { span: 8 },
                components: [
                  {
                    name: 'form-item',
                    props: {
                      prop: 'logo',
                      label: '团队logo',
                      required: true,
                      type: 'upload',
                      accept: 'image/jpeg',
                      notice: '请上传700*700尺寸的图片',
                      rules: [
                        {
                          required: true,
                          message: '请选择团队logo',
                          trigger: 'change',
                        },
                      ],
                    },
                    slots: { content: 'imageLogo' },
                  },
                ],
              },
            ],
          },

          // {
          //   name: 'form-item',
          //   props: {
          //     prop: 'name',
          //     label: '团队名称',
          //     required: true,
          //     rules: [
          //       {
          //         required: true,
          //         message: '请输入团队名称',
          //         trigger: 'change',
          //       },
          //     ],
          //   },
          // },
          // {
          //   name: 'el-row',
          //   components: [
          //     {
          //       name: 'el-col',
          //       props: { span: 8 },
          //       components: [
          //         {
          //           name: 'form-item',
          //           props: {
          //             prop: 'province_id',
          //             required: true,
          //             label: '地域',
          //             type: 'select',
          //             data: {
          //               name: 'user_group',
          //               keys: 'province_id',
          //               isAll: false,
          //             },
          //             rules: [
          //               {
          //                 required: true,
          //                 message: '请选择地域',
          //                 trigger: 'change',
          //               },
          //             ],
          //           },
          //         },
          //       ],
          //     },
          //     {
          //       name: 'el-col',
          //       props: { span: 8 },
          //       components: [
          //         {
          //           name: 'form-item',
          //           props: {
          //             prop: 'type',
          //             required: true,
          //             label: '类别',
          //             type: 'select',
          //             data: { name: 'user_group', keys: 'type', isAll: false },
          //             filterable: false,
          //             rules: [
          //               {
          //                 required: true,
          //                 message: '请选择地域',
          //                 trigger: 'change',
          //               },
          //             ],
          //           },
          //         },
          //       ],
          //     },
          //     {
          //       name: 'el-col',
          //       props: { span: 8 },
          //       components: [
          //         {
          //           name: 'form-item',
          //           props: {
          //             prop: 'logo',
          //             label: '团队logo',
          //             required: true,
          //             type: 'upload',
          //             accept: 'image/jpeg',
          //             notice: '请上传700*700尺寸的图片',
          //             rules: [
          //               {
          //                 required: true,
          //                 message: '请选择团队logo',
          //                 trigger: 'change',
          //               },
          //             ],
          //           },
          //           slots: { content: 'imageLogo' },
          //         },
          //       ],
          //     },
          //   ],
          // },
          {
            name: 'el-row',
            components: [
              {
                name: 'el-col',
                props: { span: 12 },
                components: [
                  {
                    name: 'form-item',
                    hidden: cid == 0,
                    props: {
                      prop: 'is_copy_member',
                      label: '是否复制原团队成员',
                      labelWidth: '180px',
                      defaultValue: 1,
                      type: 'radio',
                      data: [
                        { label: '是', value: 1 },
                        { label: '否', value: 0 },
                      ],
                    },
                    callback(c) {
                      c.props.hidden = isEdit;
                    },
                  },
                ],
              },
              {
                name: 'el-col',
                props: { span: 12 },
                components: [
                  {
                    name: 'form-item',
                    hidden: cid == 0,
                    props: {
                      prop: 'is_copy_datum',
                      label: '是否复制原团队题目题单',
                      labelWidth: '180px',
                      type: 'radio',
                      defaultValue: 1,
                      data: [
                        { label: '是', value: 1 },
                        { label: '否', value: 0 },
                      ],
                    },
                    callback(c) {
                      c.props.hidden = isEdit;
                    },
                  },
                ],
              },
            ],
          },
          {
            name: 'form-item',
            props: {
              prop: 'description',
              label: '团队介绍',
              type: 'editor',
              required: true,
              editorConfig: {
                module: 'default',
                initialFrameHeight: 100,
                maximumWords: 2000,
              },
              style: { position: 'relative', zIndex: 0 },
            },
          },

          {
            name: 'el-row',
            components: [
              // {
              //   name: 'el-col',
              //   props: { span: 12 },
              //   components: [
              //     {
              //       name: 'form-item',
              //       props: {
              //         prop: 'cover',
              //         label: '团队封面',
              //         required: true,
              //         type: 'upload',
              //         accept: 'image/jpeg',
              //         notice: '请上传700*700尺寸的图片',
              //         rules: [
              //           {
              //             required: true,
              //             message: '请选择团队封面',
              //             trigger: 'change',
              //           },
              //         ],
              //       },
              //       slots: { content: 'imageUrl' },
              //     },
              //   ],
              // },
              // {
              //   name: 'el-col',
              //   props: { span: 12 },
              //   components: [
              //     {
              //       name: 'form-item',
              //       props: {
              //         prop: 'logo',
              //         label: '团队logo',
              //         required: true,
              //         type: 'upload',
              //         accept: 'image/jpeg',
              //         notice: '请上传700*700尺寸的图片',
              //         rules: [
              //           {
              //             required: true,
              //             message: '请选择团队logo',
              //             trigger: 'change',
              //           },
              //         ],
              //       },
              //       slots: { content: 'imageLogo' },
              //     },
              //   ],
              // },
            ],
          },
        ]"
      >
        <!-- accept:'image/*' -->
        <template slot="imageUrl" slot-scope="{ fileList }">
          <el-image
            fit="contain"
            v-if="(fileList || []).length >= 1"
            :src="fileList[0].url"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"
            ><span>封面上传</span></i
          >
        </template>
        <template slot="imageLogo" slot-scope="{ fileList }">
          <el-image
            fit="contain"
            v-if="(fileList || []).length >= 1"
            :src="fileList[0].url"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"
            ><span>logo上传</span></i
          >
        </template>
      </form-create>
      <div v-if="isEdit">
        <div style="display: flex">
          <span style="width: 110px; text-align: right; font-size: 14px"
            >成员列表</span
          >
          <el-table
            :data="memberData"
            v-loading="memberDataLoading"
            style="width: 100%; margin-left: 10px"
            :row-style="{ height: '0' }"
            :cell-style="{ padding: '6px' }"
            :header-cell-style="{
              height: '50',
              padding: '4px',
              background: '#F9F9F9',
            }"
          >
            <el-table-column prop="user_id" align="center" label="ID">
            </el-table-column>
            <el-table-column prop="realname" align="center" label="成员姓名">
            </el-table-column>
            <el-table-column prop="sex" align="center" label="性别">
            </el-table-column>
            <el-table-column prop="identity" align="center" label="身份">
            </el-table-column>
            <el-table-column prop="phone" align="center" label="手机号">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  v-if="scope.row.identity != '管理员(创建人)'"
                  @click.stop="setManager(scope.row)"
                >
                  修改成员身份
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination" style="margin-top: 20px">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :total="memberTotal"
            @current-change="membercurrentChange"
            @size-change="memberSizeChange"
            :page-size="memberpageSize"
            :current-page="memberpage"
          >
          </el-pagination>
        </div>
        <div style="display: flex; margin-top: 20px">
          <span style="width: 110px; text-align: right; font-size: 14px"
            >关注团队列表</span
          >
          <el-table
            v-loading="focusTeamDataLoading"
            :data="focusTeamData"
            :row-style="{ height: '0' }"
            :cell-style="{ padding: '6px' }"
            :header-cell-style="{
              height: '50',
              padding: '4px',
              background: '#F9F9F9',
            }"
            style="width: 100%; margin-left: 10px"
          >
            <el-table-column prop="name" align="center" label="团队名称">
            </el-table-column>
            <el-table-column prop="user_realname" align="center" label="创建人">
            </el-table-column>
            <el-table-column
              prop="manager_user_count"
              align="center"
              label="管理员人数"
            >
            </el-table-column>
            <el-table-column
              prop="member_user_count"
              align="center"
              label="成员人数"
            >
            </el-table-column>
            <el-table-column prop="create_time" align="center" label="创建时间">
            </el-table-column>
            <el-table-column prop="f_valid_s" align="center" label="状态">
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination" style="margin-top: 20px">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :total="teamTotal"
            @current-change="teamcurrentChange"
            @size-change="teamSizeChange"
            :page-size="teampageSize"
            :current-page="teampage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button
        type="primary"
        size="small"
        v-if="cid && teamInfo.identity == '创建人'"
        @click="addTeam"
        >添加关注团队</el-button
      >
      <!-- <el-button
        type="primary"
        size="small"
        @click="clickAdd(true)"
        v-if="id && id == form.id"
        >保存</el-button
      > -->
      <el-button type="primary" size="small" @click="clickAdd(false)"
        >添加成员</el-button
      >
      <el-button type="primary" size="small" @click="clickAdd(true)" v-if="cid"
        >保存</el-button
      >

      <el-button type="primary" @click="$routerGo(-1, { path: '/team' })"
        >取消</el-button
      >
    </div>
    <!-- 弹框 -->
    <el-dialog
      class="dialogVisible"
      title=""
      :visible.sync="dialogVisible"
      width="70%"
      :show-close="false"
    >
      <div class="header">
        <span>添加团队成员</span>
        <i class="el-icon-circle-close" @click="cancel"></i>
      </div>
      <div class="body">
        <el-row :gutter="20" class="body-item">
          <el-col :span="8" style="display: flex; align-items: center">
            <div style="white-space: nowrap; margin-right: 20px">选择团队</div>
            <el-select
              v-model="searchValue"
              filterable
              remote
              style="width: 60%"
              :remote-method="inputChange"
              placeholder="请搜索"
              clearable
              @change="getMemberList"
            >
              <el-option
                v-for="item in teamList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <div class="subject" style="margin-right: 20px">选择成员</div>
            <el-input
              style="width: 60%"
              @change="search"
              @keyup.enter.native="search"
              v-model="searchStu"
              clearable
              placeholder="请输入学生姓名或手机号"
            ></el-input>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <div style="font-size: 14px">导入成员：</div>
            <div style="margin-top: -6px">
              <el-upload
                ref="upload"
                action="#"
                :before-upload="beforeUpload"
                :on-error="onError"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <div class="filename">{{ filename }}</div>
            </div>
            <el-button
              type="text"
              @click.stop="
                $tool.fileResponse(
                  '',
                  { file: 'import/white_user.xlsx' },
                  '导入模板.xlsx'
                )
              "
              size="small"
              >下载导入模板</el-button
            >
          </el-col>
        </el-row>
        <div style="margin-bottom: 20px">
          <div
            v-if="nloginsList.length > 0"
            style="font-size: 14px; color: red"
          >
            表格内以下学生未注册：{{ nloginsList.join("、") }},请确认后添加！
          </div>
          <div v-if="dupsList.length > 0" style="font-size: 14px; color: red">
            表格内以下学生重名：{{ dupsList.join("、") }},请确认后添加！
          </div>
        </div>
        <el-table
          stripe
          type="selection"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="stuLoading"
          @selection-change="selectPeople"
          :row-key="(row) => row.id"
        >
          <el-table-column
            type="selection"
            align="center"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column label="ID" align="center">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column prop="realname" label="成员" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="sex" label="性别" align="center">
          </el-table-column>
        </el-table>
        <div class="select">已选择：{{ selectPeopleList.length }}</div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="getMemberList((page = $event))"
            :page-size="pageSize"
            :current-page="page"
            :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
        <!-- <div class="save-btn"> -->
      
        <!-- </div> -->
      </div> 
      <div slot="footer" class="dialog-footer">
          <div class="save" @click="determine('form')">确认</div>
          <div class="cancel" @click="cancel">取消</div>
        </div>
    </el-dialog>
    <chooseTeam ref="chooseTeam" :cid="cid"></chooseTeam>
  </div>
</template>

<script>
import {
  member_list,
  teamList,
  createTeam,
  rule,
  teamDetail,
} from "@/api/team.js";
import chooseTeam from "./components/chooseTeam.vue";

export default {
  components: {
    chooseTeam,
  },
  props: ["team_id"],
  data() {
    //这里存放数据
    return {
      id: 0,
      cid: 0, //团队id
      action: this.$store.state.action,
      nameForm: {
        name: "",
      },
      rule: "",
      stuLoading: false,
      memberDataLoading: false,
      memberDataLoading: false,
      page: 1,
      pageSize: 10,
      memberpageSize: 10,
      memberpage: 1,
      memberTotal: 0,
      teampageSize: 10,
      teampage: 1,
      teamTotal: 0,
      teamList: [],
      info: {},
      typeList: [],
      provinceList: [],
      tableData: [],
      total: 0,
      form: {},
      selectPeopleList: [],

      dialogVisible: false,
      searchValue: "",
      rules: {
        name: [{ required: true, message: "请输入团队名称", trigger: "blur" }],
      },
      searchStu: "", //搜索值
      isEdit: false,
      filename: "",
      teamInfo: {}, //团队信息
      dupsList: [], //重名
      nloginsList: [], //未注册
      memberData: [], //成员列表
      focusTeamData: [], //关注团队列表
    };
  },
  created() {
    this.isEdit = this.$route.query.isEdit;
  },
  //方法集合
  methods: {
    routeUpdate() {
      this.cid = parseInt(this.$route.query.cid || 0);
      console.log(this.cid, "this.cid");
      this.id = parseInt(this.team_id || this.$route.query.id || 0);
      if (this.id || this.cid) {
        this.getDetails();
        this.getMemberData();
        this.getFocusTeam();
      }
      rule({})
        .then((res) => {
          // console.log(res, "resssssssssss");
          this.rule = res.data;
        })
        .catch();
      this.inputChange();
    },

    // 判断是否有重名
    checkName() {
      teamList({ page_size: 999 }).then((res) => {
        // console.log(res, "resssssss");
        let result = res.data.data.some((v) => v.name == this.nameForm.name);
        if (result)
          return this.$message.error("该团队名称已存在，请更换团队名！");
      });
    },
    handleFormUpdate({ prop, value, vNode }) {
      console.log(prop, "propprop");
      console.log(value, "valuevalue");
      console.log(vNode, "vNodevNode");
      if (prop === "name") {
        teamList({ page_size: 999 }).then((res) => {
          // console.log(res, "resssssss");
          let result = res.data.data.some((v) => v.name == value);
          if (result)
            return this.$message.error("该团队名称已存在，请更换团队名！");
        });
      }
    },
    // 设置为管理员
    setManager(e) {
      this.$confirm("是否修改该成员身份?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            group_id: this.cid,
            user_id: e.user_id,
            identity: e.identity == "成员" ? "管理员" : "成员",
          };
          this.$http
            .post("/xapi/user.group/changeIdentity", data)
            .then((res) => {
              this.$message({
                type: "success",
                message: "设置成功!",
              });
              this.getMemberData();
            })
            .catch(({ code, msg }) => {
              console.log(code);
              console.log(msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消设置",
          });
        });
    },
    getMemberData() {
      this.memberDataLoading = true;
      this.$http
        .post(
          `xapi/user.group/memberList?page=` +
            this.memberpage +
            "&pageSize=" +
            this.memberpageSize +
            "&team_id=" +
            this.cid +
            "&type=0"
        )
        .then((res) => {
          this.memberDataLoading = false;
          this.memberTotal = res.data.total;
          console.log(this.memberTotal, "memberTotal");
          this.memberData = res.data.data;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    getFocusTeam() {
      this.focusTeamDataLoading = true;
      this.$http
        .post("/api/v1_0_0.user_group", {
          page: this.teampage,
          page_size: this.teampageSize,
          is_focus: 1,
          from_id: this.cid,
        })
        .then((res) => {
          this.focusTeamDataLoading = false;
          this.teamTotal = res.data.total;
          this.focusTeamData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    membercurrentChange(e) {
      this.memberpage = e;
      this.getMemberData();
    },
    memberSizeChange(e) {
      this.memberpageSize = e;
      this.getMemberData();
    },
    teamcurrentChange(e) {
      this.teampage = e;
      this.getFocusTeam();
    },
    teamSizeChange(e) {
      this.teampageSize = e;
      this.getFocusTeam();
    },
    getDetails() {
      var id = this.id || this.cid;
      teamDetail({ id: id })
        .then(({ data }) => {
          this.teamInfo = data.user;
          this.form = {
            ...data.info,
            id: this.cid + "" === data.info.id + "" ? null : this.id,
            ...this.form,
          };
          console.log(this.form, "form");
          this.nameForm.name = data.info.name;
        })
        .catch((err) => {});
    },
    // 成员列表
    getMemberList() {
      this.stuLoading = true;
      member_list({
        page: this.page,
        page_size: this.pageSize,
        keyword: this.searchStu,
        group_id: this.searchValue,
        un_group_id: this.cid,
      })
        .then(({ data }) => {
          this.stuLoading = false;

          console.log(data, "成员列表成功");
          this.tableData = [...data.data];
          this.total = data.total;
        })
        .catch((err) => {
          console.log(err, "成员列表失败");
        });
    },
    search() {
      this.getMemberList();
    },
    // 选择团队
    inputChange(e) {
      teamList({ keyword: e })
        .then(({ data }) => {
          this.teamList = data.data;
        })
        .catch();
    },
    // 选择成员的函数
    selectPeople(val) {
      this.selectPeopleList = val;
    },
    // 确定成员
    determine(formName) {
      // if (!this.selectPeopleList.length) {
      //   this.$message.error("请选择成员")
      //   return
      // }
      this.$refs[formName].validate((form, valid) => {
        if (valid) {
          console.log(form, "formmmm");
          // return
          if (!form.is_copy_member) {
            form.is_copy_member = 1;
          }
          if (!form.is_copy_datum) {
            form.is_copy_datum = 1;
          }
          form.file = this.file;
          // console.log(form, "formData");
          var _formData = new FormData();
          let data = { ...form };
          // console.log(data, "datadata");

          for (const dataKey in data) {
            // console.log(dataKey,"keyyyyyyyyyy")
            // console.log(data[dataKey],"data[dataKey]data[dataKey]")
            _formData.append(dataKey, data[dataKey]);
          }
          // _formData.append("name", this.nameForm.name);
          _formData.append("pid", this.isEdit ? 0 : this.cid);
          _formData.append(
            "members",
            this.selectPeopleList.map((v) => v.id)
          );
          _formData.append("id", this.cid);
          // console.log(_formData, "_formData");
          // return
          createTeam(
            //   {
            //   // ...form,
            //   ..._formData,
            //   // name: this.nameForm.name,
            //   // pid: this.isEdit ? 0 : this.cid,
            //   // // id: this.cid === this.id ? null : this.id,
            //   // id: this.cid,
            //   // members: this.selectPeopleList.map((v) => v.id),
            // }
            _formData
          )
            .then(({ data }) => {
              this.$message.success(this.isEdit ? "编辑成功" : "创建成功");
              if (
                (data.dups && data.dups.length > 0) ||
                (data.nlogins && data.nlogins.length > 0)
              ) {
                this.dupsList = data.dups;
                this.nloginsList = data.nlogins;
              } else {
                this.dialogVisible = false;
                this.$routerGo(-1, { path: "/team" });
              }
            })
            .catch();
        }
      }, true);
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
    },
    // 添加关注团队
    addTeam() {
      console.log("添加关注团队");
      this.$refs.chooseTeam.openTeamDialog();
    },

    clickAdd(isEdit = false) {
      this.$refs["form"].validate((form, valid) => {
        if (valid) {
          if (isEdit !== true) {
            this.page = 1;
            this.getMemberList();
            this.dialogVisible = true;
          } else {
            console.log(form, "form");
            createTeam({ ...form, id: this.cid })
              .then(() => {
                this.$message.success("编辑成功");
                this.$routerGo(-1, { path: "/team" });
              })
              .catch();
          }
        } else {
          this.$message.error("创建团队信息填写不完整");
        }
      }, true);
    },
    beforeUpload(file) {
      const isExcel = /\.(xlsx|xls|csv)$/.test(file.name);
      if (!isExcel) {
        this.$message.error("只能上传.xlsx、.xls、.csv 文件!");
        return false;
      }
      this.file = file;
      console.log(this.file, "fileeeeee");
      this.filename = file.name;
      console.log(this.filename, "this.filename");
      return false;
    },
    onSuccess(response, file, fileList) {
      // 文件上传成功的处理逻辑
      console.log("File uploaded successfully:", response);
      console.log("File uploaded successfully:", file.raw);
      console.log("File uploaded successfully:", fileList);
    },
    onError(err, file, fileList) {
      // 文件上传失败的处理逻辑
      console.error("Error while uploading file:", err);
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>

::v-deep {
  .el-row {
    //margin-bottom: 10px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
  }
  .avatar {
    width: 130px;
    height: 130px;
    display: block;
  }
  .pagination {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }
  .introduce {
    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #40b292;
      line-height: 25px;
    }
    div {
      line-height: 25px;
    }
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2d2d2d;
    line-height: 50px;
  }
  .el-form {
    margin-top: 20px;
    .el-input {
      height: 30px;
      .el-input__inner {
        height: 100%;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
  }
}
.establish_team {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  // border: 1px solid #979797;
  padding: 3.4% 9% 4% 6.1%;

  ::v-deep {
    .el-upload {
      border: 1px dashed #40b292;
      border-radius: 5px;
    }
    .avatar-uploader-icon {
      line-height: 0;
    }
    .el-icon-plus {
      color: #40b292;
      font-size: 22px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        margin-top: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 3%;
}
.dialogVisible {

  ::v-deep {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-input__inner {
      // border-radius: 40px;
      border: 1px solid #d4d4d4;
      height: 30px;
      line-height: 30px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-top: 1px solid #ebeef5;
      //  border-bottom: 1px solid #EBEEF5;
    }
  }
  .header {
    height: 35px;
    background: #00957e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    line-height: 35px;
    align-items: center;
    .el-icon-circle-close {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .body {
    padding: 0 10% 2rem;
    margin: 0 auto;
    .body-item-table {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      // .subject {
      // }
    }
    .body-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;
      .select {
        display: flex;
        justify-content: space-between;
        > div {
          width: 48%;
        }
      }
    }
    ::v-deep {
      .el-table th {
        background-color: #f2f2f2;
      }
      .el-table--striped .el-table__body tr.el-table__row--striped td {
        background: #f2f2f2;
      }
      .el-table td,
      .el-table th.is-leaf {
        border: 0;
      }
      .el-table td,
      .el-table th {
        padding: 0;
        height: 30px;
      }
      .cell {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323232;
        line-height: 30px;
      }
    }
    .select {
      margin-top: 2%;
    }
    .save-btn {
      height: 2rem;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
    }
    .save,
    .cancel {
      float: right;
      bottom: 2rem;
      right: 2rem;
      width: 40px;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      padding: 0px 16px;
      text-align: center;
      width: 74px;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
    }
    .cancel {
      margin-right: 2rem;
      color: #333333;
      background: #ffffff;
      border: 1px solid #979797;
    }
  }
  .dialog-footer{
    height: 35px;
  .save,
    .cancel {
      float: right;
      bottom: 2rem;
      right: 2rem;
      width: 40px;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      padding: 0px 16px;
      text-align: center;
      width: 74px;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
    }
    .cancel {
      margin-right: 2rem;
      color: #333333;
      background: #ffffff;
      border: 1px solid #979797;
    }
  }

}
::v-deep {
  .el-button--primary {
    background-color: #00957e;
  }
  .el-input__inner {
    font-size: 14px;
  }
  .el-button {
    // border: 1px solid #00957e;
    width: 86px;
    height: 30px;
    line-height: 30px;
    padding: 0;
  }
}
</style>